#events-container {
    margin: 30px auto;
    display: flex;
    width: 1000px;
    flex-wrap: wrap;
    justify-content: space-around;
}

@media only screen and (max-width:1250px) {
    #events-container {
        width: 95%;
        margin-top: 25px;
    }
}

#cart {
    width: 300px;
    margin: 30px 5px;

}

#cart-img {
    width: 290px;
    height: 320px;
    border-radius: 15px;
}

@media only screen and (max-width:720px) {
    #cart-img {
        width: 100%;
    }
}

#cart-body {
    display: flex;
    flex-direction: column;
    padding: 5px 10px;
}

#cart-title {
    font-family: system-ui;
    padding-bottom: 5px;

    overflow: hidden;
    white-space: nowrap; 
    text-overflow: ellipsis;
    
}

#cart-body div {
    margin-top: 6px;
    display: flex;
}

#cart-body div p {
    padding: 5px 3px;
}

#cart-price {
    font-weight: 700;
    padding-left: 23px;
    color: rgb(250, 95, 39);
    font-family: system-ui;
}

#cart-detail {
    text-decoration: none;
    margin-top: 5px;
    padding: 8px 50px;
    color: white;
    background-color: rgb(250, 95, 39);
    display: inline-block;
    border-radius: 13px;
    
}

#cart-address {
    color: rgb(128, 127, 127);
    font-size: large;
}

@media only screen and (max-width:720px) {
    #cart-detail {
        padding: 7px 50px;
    }
    #cart-body div {
        margin-top: 1px;
    }
    #cart-body div p {
        padding-top:7px;
        font-size: 13px;
    }

}