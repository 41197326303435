#filter {
    padding-top: 10px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width:185px;
}
.filter-item{
    margin-top: 7px;
}
#filter-open-btn {
    visibility: hidden;
}

#close-filter {
    font-family: system-ui;
    font-weight: 700;
    font-size: 30px;
}

.filter-item select{
    background-color: white;
    width: auto;
    width: 100%;
    /* width: 180px;
    box-shadow: none; */
}
option{
    margin-top: 5px;
}
.datalist-input{
    width: 90%;
}
input[type="checkbox"]{
    margin-left: 13px;
    margin-top: 5px;
}
#filter-btn-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
#filter-btn{
    opacity: 0.7;
    padding: 10px ;
    width: 93%;
    margin-top: 10px;
    border-radius: 15px;
    background-color: rgb(250, 95, 39);
    border: none;
    color: white;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
#filter-btn:hover{
    opacity: 0.8;
    
}
#reset-filter-btn{
    opacity: 0.8;
    border : 1px solid rgb(195, 194, 194);
    padding: 7px ;
    width: 93%;
    margin-top: 10px;
    border-radius: 13px;
    background-color: rgb(233, 229, 229);
    color: rgb(168, 166, 166);
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
#reset-filter-btn:hover{
    opacity: 1;
    color: rgb(132, 131, 131);
}

@media only screen and (max-width:1250px) {

    #filter {
        all: unset;
        width: 210px;
        position: absolute;
        left: 10px;
        top: 55px;
        background-color: white;
        border-radius: 20px;
        padding: 10px 10px;
        visibility: hidden;
        /* box-shadow: -1px 1px 4px 1px rgb(194, 190, 190); */
        background-color: rgb(250, 249, 249);
        border: 1px solid rgb(231, 231, 231);
        z-index: 2;
    }

    #filter-open-btn {
        z-index: 3;
        display: flex;
        align-items: center;
        padding: 5px;
        visibility: visible;
        position: absolute;
        left: 20px;
        border: 0.1px solid rgb(233, 232, 232);
        margin-top: 8px;
        border-radius: 8px;
        background-color: rgb(250, 250, 250);
        /* box-shadow: 0px 0px 6px 1px rgb(230, 229, 229); */
    }

    #filter.open-filter {
        visibility: visible;
    }

    #fbtn-text{
        padding:5px 10px;
    }
}