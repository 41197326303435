#banner {
    width: 100%;
    margin-top: -79.5px;
    position: relative;
}

#banner-img {
    display: block;
    width: 100%;
    margin: auto;
    height: auto;
    max-height: 890px;
    min-height: 350px;
    filter: brightness(0.6);
}

#banner-content {
    position: absolute;
    top: 45%;
    left: 100px;
}

#b-title {
    -webkit-text-fill-color: transparent;
    background: -webkit-linear-gradient(rgb(247, 108, 57), rgb(247, 108, 57), #ebebec);
    -webkit-background-clip: text;
    color: white;
    font-size: 70px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

#banner-content p {
    width: 450px;
    color: white;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

#text-background {
    background-color: rgb(247, 108, 57);
}

#b-button-register {
    background-color: rgb(247, 108, 57);
    border: none;
    padding: 13px 50px;
    border-radius: 10px;
    margin-top: 20px;
    color: white;
    font-weight: 700;
    font-size: 20px;
}

@media only screen and (max-width:1000px) {
    #banner {
        margin-top: 0px;
    }

    #b-title {
        font-size: 50px;
    }

    #banner-content {
        margin-top: 45px;
        position: absolute;
        left: 100px;
        top: 50%;
        transform: translateY(-50%);
    }
    #b-button-register {
        padding: 11px 35px;
        color: white;
        font-weight: 600;
        font-size: 16px;
    }
}


#populerEventsTitle{
    font-size: 35px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-align: center;
    margin-top: 60px;
}
@media only screen and (max-width:700px) {

    #b-title {
        font-size: 32px;
    }

    #banner-content {
        margin-top: 55px;
        left: 17px;
    }

    #banner-content p {
        font-size: 12px;
        width: 340px;
    }

    #b-button-register {
        padding: 10px 35px;
        color: white;
        font-weight: 600;
        font-size: 13px;
    }
    #populerEventsTitle{
        font-size: 30px;
    }
}

#loaderWrapper{
    margin-top: 100px;
    margin-bottom: 100px;
    height: 300px;
}